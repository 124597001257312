<template>
  <div>
    <ChooseImportEmployee ref="modalImportEmployee" />
    <UploadExcel
      ref="modalUpload"
      :campaignId="campaignId"
      :text="textModalUpload"
      @upload="uploadCallbackModal"
    />
    <EmployeeDetails ref="modalEmployeeDetails" :title="titleDetailsEmployee" />
    <ConciliationUploadDetails ref="modalConciliationUploadDetails" :title="titleDetailsEmployee" />
    <!-- page title ################### -->
    <div class="big-head">
      <faIcon type="fal" name="users" />
      <h1>
        Conciliação RH
        <em></em>
      </h1>
    </div>
    <v-container fluid grid-list-sm>
      <!-- Select da campanha -->
      <v-layout align-center px-2 py-0 wrap mw-half>
        <v-flex xs12>
          <v-select
            v-model="campaignId"
            :items="listCampaign"
            item-text="name"
            item-value="id"
            placeholder="Campanha"
            class="teste"
            @change="changeSelect"
          >
            <template slot="item" slot-scope="data">
              <template>
                <v-layout row pr-2>
                  <v-list-tile-title v-html="data.item.name"></v-list-tile-title>
                  <span class="badge ml-auto" :class="'bg-green'" v-html="data.item.countEmploies"></span>
                </v-layout>
              </template>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs12>
          <div v-if="campaignSelected != null" class="mt-3">
            Base total de funcionários
            <span
              class="f-right"
            >{{campaignSelected.countEmploies | FormatNumber}}</span>
          </div>
          <div v-if="campaignSelected != null" class="text-red mt-1">
            A Conciliar
            <span class="f-right">{{ campaignSelected.countToConciliate | FormatNumber }}</span>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <hr class="md my-2" />

    <!-- boxapp body -->
    <div class="box-main">
      <div class="section">
        <!-- base funcionarios ############################ -->
        <div class="base mr-4 mb-5">
          <div class="base-head">
            <h4>
              <faIcon type="fal" name="users" class="mr-3" />Base de Funcionários
            </h4>
            <p>
              Mantenha sempre atualizada a base de funcionários.
              <br />Minimize registros inconsistentes ou incompletos para melhorar a performance de doações da empresa.
            </p>
          </div>

          <div class="resume ph-load ph-card-big" :class="processingUpload ? 'loading' : ''">
            <!-- toggle .loading -->

            <!--loader -->
            <div v-if="showLoader">{{progressUpload}}%</div>
            <v-progress-linear
              v-if="processingUpload"
              :indeterminate="true"
              value="progressUpload"
              height="4"
              color="bg-primary"
            ></v-progress-linear>
            <!-- #### Arte Resumo Upload Vazio ####-->
            <div v-if=" listImportEmployee.length <= 0">
              <div class="il il-Resources"></div>
            </div>
            <v-btn
              flat
              color="white"
              class="btn-primary ma-0"
              large
              @click="openModalUpload('employee')"
            >
              <i class="fas fa-upload mr-3"></i> Atualizar base de funcionários
            </v-btn>
            <!-- Controle do resumo ########################## -->
            <div v-if=" listImportEmployee.length > 0">
              <!-- resumo upload ##########################-->
              <h4>Resumo do último upload</h4>

              <hr class="md my-2" />

              <div class="text-orange fw-700">
                Data
                <span
                  class="f-right"
                >{{ listImportEmployee.length > 0 ? $moment.utc(currentImportEmployee.uploadDate).local('America/Sao_paulo').format('DD/MM/YYYY HH:mm') : '-' }}</span>
              </div>
              <div class="text-primary fw-700">
                Usuário
                <span
                  class="f-right"
                >{{ listImportEmployee.length > 0 ? currentImportEmployee.user.persons[0].name : '-' }}</span>
              </div>
              <div>
                Registros
                <span
                  class="f-right"
                >{{ listImportEmployee.length > 0 ? currentImportEmployee.registers : '-' | FormatNumber}}</span>
              </div>
              <div>
                Funcionários importados com sucesso
                <span
                  class="f-right"
                >{{ listImportEmployee.length > 0 ? currentImportEmployee.registersSuccess : '-' | FormatNumber}}</span>
              </div>

              <hr class="md my-2" />

              <div>
                <a @click="openModalEmployeeInconsistents" class="text-yellow fw-700">
                  <i class="fas fa-exclamation-circle mr-2"></i>Total de registros inconsistentes
                  <span
                    class="f-right"
                  >{{ listImportEmployee.length > 0 ? currentImportEmployee.registersError : '-' | FormatNumber}}</span>
                </a>
              </div>
              <div>
                <a @click="openModalEmployeeDuplicated" class="text-yellow fw-700">
                  <i class="fas fa-exclamation-circle mr-2"></i>Registros Duplicados
                  <span
                    class="f-right"
                  >{{ listImportEmployee.length > 0 ? currentImportEmployee.registersDuplicated : '-' | FormatNumber}}</span>
                </a>
              </div>

              <hr class="md my-2" />

              <!-- comparativo bases ##########################-->
              <h4 class="mt-4">Comparativo de Bases</h4>

              <hr class="md my-2" />

              <!-- <div>
                Novos funcionários
                <span
                  class="f-right"
                >{{ listImportEmployee.length > 0 ? currentImportEmployee.newRegisters : '-' }}</span>
              </div>-->
              <div>
                <a class="text-blue" @click="openModalNewEmployees">
                  <i class="fas fa-info-circle mr-2"></i>Novos funcionários
                  <span
                    class="f-right"
                  >{{ listImportEmployee.length > 0 ? currentImportEmployee.newRegisters : '-' | FormatNumber}}</span>
                </a>
              </div>
              <div>
                <a class="text-blue" @click="openModalEmployeeDetailsRemoved">
                  <i class="fas fa-info-circle mr-2"></i>Saíram da base
                  <span
                    class="f-right"
                  >{{ listImportEmployee.length > 0 ? currentImportEmployee.registersRemoved : '-' | FormatNumber}}</span>
                </a>
              </div>
              <div>
                <a class="text-blue" @click="openModalEmployeeDetailsChangedEmailGroup">
                  <i class="fas fa-info-circle mr-2"></i>Mudaram de e-mail ou empresa do grupo
                  <span
                    class="f-right"
                  >{{ listImportEmployee.length > 0 ? currentImportEmployee.emailGroupChanged : '-' | FormatNumber}}</span>
                </a>
              </div>
              <hr class="md my-2" />
            </div>

            <v-btn
              flat
              round
              color="white"
              class="btn-ghost-primary mt-3 ma-0"
              large
              @click="downloadBaseEmployee"
              v-if="listImportEmployee.length > 0 && currentImportEmployee.baseEmployeeAfterImport > 0"
            >
              <i class="fas fa-download mr-3"></i> Download da base de funcionários
              <span
                class="badge bg-primary ml-3"
              >{{currentImportEmployee.baseEmployeeAfterImport | FormatNumber}}</span>
            </v-btn>
            <a
              href="https://aucreceipts.blob.core.windows.net/filesprod/Templates/Upload_base_Funcionários.xlsx"
              target="_blank"
              class="v-btn v-btn--flat v-btn--large w-100 btn-ghost-primary mt-3 ma-0"
            >
              <i class="fas fa-download mr-3"></i> Download do layout da base
            </a>
          </div>
        </div>

        <!-- base conciliacao ############################ -->
        <div class="base">
          <div class="base-head">
            <h4>
              <faIcon type="fal" name="file-invoice-dollar" class="mr-3" />Base de Conciliação
            </h4>
            <p>
              Envie a base conciliada com os débitos em folha de pagamento.
              <br />Certifique-se de todos os registros estarem consistentes.
            </p>
          </div>

          <div class="resume ph-load ph-card-sm">
            <div v-if="currentPayRollConciliationActionDownload == null">
              <!-- 
                ###########################
                Arte Resumo Download Vazio
              ##########################-->
              <div class="il il-DownloadFile md"></div>
            </div>
            <v-btn
              v-if="campaignSelected != null && campaignSelected.countToConciliate > 0"
              flat
              round
              color="white"
              class="btn-ghost-red ma-0"
              large
              @click="downloadBaseToConciliate"
            >
              <i class="fas fa-download mr-3"></i> Download da base a conciliar
              <span
                class="badge bg-red ml-3"
              >{{campaignSelected.countToConciliate}}</span>
            </v-btn>
            <!-- resumo upload ##########################-->
            <div v-if="currentPayRollConciliationActionDownload != null">
              <h4>Resumo do último download</h4>

              <hr class="md my-2" />

              <div class="text-orange fw-700">
                Data
                <span
                  class="f-right"
                >{{$moment.utc(currentPayRollConciliationActionDownload.creationDate).local('America/Sao_paulo').format('DD/MM/YYYY HH:mm')}}</span>
              </div>
              <div class="text-primary fw-700">
                Usuário
                <span
                  class="f-right"
                >{{ currentPayRollConciliationActionDownload.user.persons[0].name }}</span>
              </div>
              <div>
                Registros
                <span
                  class="f-right"
                >{{currentPayRollConciliationActionDownload.registersQuantity | FormatNumber}}</span>
              </div>

              <hr class="md my-2" />

              <div class="text-red">
                Valor total da base a concilar
                <span
                  class="f-right fw-700"
                >{{currentPayRollConciliationActionDownload.totalValue | FormatMoney}}</span>
              </div>
            </div>
          </div>
          <div
            class="resume mt-3 ph-load ph-card-sm"
            :class="processingConciliationUpload ? 'loading' : ''"
          >
            <v-progress-linear
              v-if="processingConciliationUpload"
              :indeterminate="true"
              value="progressUpload"
              height="4"
              color="bg-primary"
            ></v-progress-linear>
            <div v-if="listPayRollConciliationActionUpload.length == 0">
              <!-- 
                ###########################
                Arte Resumo Upload Base Conciliada Vazio
              ##########################-->
              <div class="il il-Upload md"></div>
            </div>
            <v-btn
              flat
              round
              color="white"
              class="btn-primary ma-0"
              large
              @click="openModalUpload('conciliation')"
            >
              <i class="fas fa-upload mr-3"></i> Upload base conciliada
            </v-btn>

            <!-- resumo upload ##########################-->
            <div v-if="listPayRollConciliationActionUpload.length > 0">
              <h4>Resumo do último upload</h4>

              <hr class="md my-2" />

              <div class="text-orange fw-700">
                Data
                <span
                  class="f-right"
                >{{$moment.utc(currentPayRollConciliationActionUpload.creationDate).local('America/Sao_paulo').format('DD/MM/YYYY HH:mm')}}</span>
              </div>
              <div class="text-primary fw-700">
                Usuário
                <span
                  class="f-right"
                >{{ currentPayRollConciliationActionUpload.user.persons[0].name }}</span>
              </div>
              <div>
                Registros
                <span
                  class="f-right"
                >{{currentPayRollConciliationActionUpload.registersQuantity | FormatNumber}}</span>
              </div>
              <div>
                <a class="text-blue" @click="openModalConciliationDoesntExist">
                  <i class="fas fa-info-circle mr-2"></i>Fora da lista
                  <span
                    class="f-right"
                  >{{currentPayRollConciliationActionUpload.countFailDoesntExist | FormatNumber}}</span>
                </a>
              </div>
              <div>
                <a class="text-blue" @click="openModalConciliationDifferentValues">
                  <i class="fas fa-info-circle mr-2"></i>Com valores divergentes
                  <span
                    class="f-right"
                  >{{currentPayRollConciliationActionUpload.countFailWithDifferentValues | FormatNumber}}</span>
                </a>
              </div>
              <div>
                <a class="text-blue" @click="openModalConciliationNotUploaded">
                  <i class="fas fa-info-circle mr-2"></i>Não informados
                  <span
                    class="f-right"
                  >{{currentPayRollConciliationActionUpload.countFailNotUploaded | FormatNumber}}</span>
                </a>
              </div>
              <div>
                <a class="text-blue" @click="openModalConciliationNotOnConciliationByCompanyStatus">
                  <i class="fas fa-info-circle mr-2"></i>Não conciliados
                  <span
                    class="f-right"
                  >{{currentPayRollConciliationActionUpload.countFailNotOnConciliationByCompanyStatus | FormatNumber}}</span>
                </a>
              </div>
              <hr class="md my-2" />

              <div class="text-primary">
                Valor total da conciliação
                <span
                  class="f-right fw-700"
                >{{currentPayRollConciliationActionUpload.totalValue | FormatMoney}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- boxapp body -->
    <div class="box-main">
      <div class="section">
        <!-- base funcionarios ############################ -->
        <div class="base mr-4 mb-5">
          <div class="base-head">
            <h4>
              <faIcon type="fal" name="users" class="mr-3" />PDFs de anuencia
            </h4>
          </div>
          <div class="resume ph-load ph-card-big">
            <v-btn
              flat
              round
              color="white"
              class="btn-ghost-primary mt-3 ma-0"
              large
              @click="schedulePDFs"
            >
              <i class="fas fa-download mr-3"></i> Solicitar geração de PDFs de anuência
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="log">
      <div class="big-head">
        <h1>Log</h1>
        <faIcon type="fal" name="file-alt" class="ml-3 mr-0" />
      </div>
      <!-- tabs ###################-->
      <v-tabs
        v-model="tab"
        dark
        color="transparent"
        class="b-bottom"
        style="height:442px /*fix screen jumping*/"
      >
        <v-tabs-slider color="cyan"></v-tabs-slider>

        <!-- tab 1 -->
        <v-tab
          :key="'upload-employee'"
          :href="'#upload-employee'"
          ripple
          class="text-gray-dark text-none ml-4"
          v-if="listImportEmployee.length > 0"
        >
          <faIcon type="fas" name="upload" class="mr-2 text-gray" />Upload funcionários
        </v-tab>

        <!-- tab 2 -->
        <v-tab
          :key="'download-conciliation'"
          :href="'#download-conciliation'"
          ripple
          class="text-gray-dark text-none"
          v-if="listPayRollConciliationActionDownload.length > 0"
        >
          <faIcon type="fas" name="download" class="mr-2 text-gray" />Download base a conciliar
        </v-tab>
        <!-- tab 3 -->
        <v-tab
          :key="'upload-conciliation'"
          :href="'#upload-conciliation'"
          ripple
          class="text-gray-dark text-none"
          v-if="listPayRollConciliationActionUpload.length > 0"
        >
          <faIcon type="fas" name="upload" class="mr-2 text-gray" />Upload base conciliada
        </v-tab>

        <!-- tabs content upload employee ###################-->
        <v-tab-item
          value="upload-employee"
          :key="'upload-employee'"
          class="text-default"
          v-if="listImportEmployee.length > 0"
        >
          <v-card flat>
            <v-card-text>
              <!-- scroll -->
              <div class="scroll">
                <!-- log item ##############################################################-->
                <v-layout
                  v-for="importEmployee in listImportEmployee"
                  :key="importEmployee.id"
                  log-item
                  row
                >
                  <!-- left side -->
                  <v-flex>
                    <v-layout align-center>
                      <v-flex shrink pr-3>
                        <faIcon type="fas" name="upload" size="16px" class="text-primary" />
                        <!-- or text-red -->
                      </v-flex>
                      <v-flex>
                        <time>{{ $moment.utc(importEmployee.uploadDate).local('America/Sao_paulo').format('DD/MM/YYYY HH:mm') }}</time>
                        <h3>{{ importEmployee.user.persons[0].name }}</h3>
                        <div class="caption">Atualização da base de funcionários</div>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <!-- right side -->
                  <v-flex>
                    <v-layout align-center>
                      <v-flex text-xs-right pr-3>
                        <h4>{{ importEmployee.registers | FormatNumber }} registros</h4>
                        <!-- <h3 class="text-primary f-size-16">R$128.400,00</h3> or text-red -->
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <!--  / log item ##########################################################-->
              </div>
              <!-- /scroll -->
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- tabs download base to conciliate ###################-->
        <v-tab-item
          value="download-conciliation"
          :key="'download-conciliation'"
          class="text-default"
          v-if="listPayRollConciliationActionDownload.length > 0"
        >
          <v-card flat>
            <v-card-text v-if="listPayRollConciliationActionDownload.length > 0">
              <!-- scroll -->
              <div class="scroll">
                <!-- log item ##############################################################-->
                <v-layout
                  v-for="payRollConciliation in listPayRollConciliationActionDownload"
                  :key="payRollConciliation.id"
                  log-item
                  row
                >
                  <!-- left side -->
                  <v-flex>
                    <v-layout align-center>
                      <v-flex shrink pr-3>
                        <faIcon type="fas" name="download" size="16px" class="text-primary" />
                        <!-- or text-red -->
                      </v-flex>
                      <v-flex>
                        <time>{{ $moment.utc(payRollConciliation.creationDate).local('America/Sao_paulo').format('DD/MM/YYYY HH:mm') }}</time>
                        <h3>{{ payRollConciliation.user.persons[0].name }}</h3>
                        <div class="caption">Download da base a conciliar</div>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <!-- right side -->
                  <v-flex>
                    <v-layout>
                      <v-flex text-xs-right>
                        <h4>{{ payRollConciliation.registersQuantity | FormatNumber }} registros</h4>
                        <h3
                          class="text-primary f-size-16"
                        >{{ payRollConciliation.totalValue | FormatNumber }}</h3>
                      </v-flex>
                      <v-flex shrink>
                        <v-btn
                          flat
                          fab
                          @click="downloadBaseToConciliateFromLog(payRollConciliation)"
                        >
                          <faIcon type="fal" name="download" size="20px" />
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <!--  / log item ###########################################################-->
              </div>
              <!-- /scroll -->
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- tabs upload base to conciliate ###################-->
        <v-tab-item
          value="upload-conciliation"
          :key="'upload-conciliation'"
          class="text-default"
          v-if="listPayRollConciliationActionUpload.length > 0"
        >
          <v-card flat>
            <v-card-text>
              <!-- scroll -->
              <div class="scroll">
                <!-- log item ##############################################################-->
                <v-layout
                  v-for="payRollConciliation in listPayRollConciliationActionUpload"
                  :key="payRollConciliation.id"
                  log-item
                  row
                >
                  <!-- left side -->
                  <v-flex>
                    <v-layout align-center>
                      <v-flex shrink pr-3>
                        <faIcon type="fas" name="upload" size="16px" class="text-primary" />
                        <!-- or text-red -->
                      </v-flex>
                      <v-flex>
                        <time>{{ $moment.utc(payRollConciliation.creationDate).local('America/Sao_paulo').format('DD/MM/YYYY HH:mm') }}</time>
                        <h3>{{ payRollConciliation.user.persons[0].name }}</h3>
                        <div class="caption">Upload da base conciliada</div>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <!-- right side -->
                  <v-flex>
                    <v-layout>
                      <v-flex text-xs-right pr-3>
                        <h4>{{ payRollConciliation.registersQuantity | FormatNumber }} registros</h4>
                        <h3
                          class="text-primary f-size-16"
                        >{{ payRollConciliation.totalValue | FormatMoney }}</h3>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <!--  / log item ###########################################################-->
              </div>
              <!-- /scroll -->
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>
<script type="plain/text">
import ChooseImportEmployee from "@/views/app/resourceFlow/modal/ChooseImportEmployee.vue";
import UploadExcel from "@/views/app/resourceFlow/modal/UploadExcel.vue";
import EmployeeDetails from "@/views/app/resourceFlow/modal/EmployeeDetails.vue";
import ConciliationUploadDetails from "@/views/app/resourceFlow/modal/ConciliationUploadDetails.vue";
import EmployeeService from "@/scripts/services/employee.service.js";
import DocService from "@/scripts/services/doc.service.js";
import ImportEmployee from "@/scripts/models/importEmployee.model";
import PayRollConciliationAction from "@/scripts/models/payRollConciliationAction.model";
import ImportStatus from "@/scripts/models/enums/importStatus.enum";
import PayRollConciliationActionStatus from "@/scripts/models/enums/payRollConciliationActionStatus.enum";
import PayRollConciliationActionOperation from "@/scripts/models/enums/payRollConciliationActionOperation.enum";
import PayRollConciliationActionDonationStatus from "@/scripts/models/enums/payRollConciliationActionDonationStatus.enum";
import ImportEmployeeStatus from "@/scripts/models/enums/importEmployeeStatus.enum";
import { type } from "os";
import CampaignService from "@/scripts/services/campaign.service.js";
import { mapGetters, mapState } from "vuex";
import Permissions from "@/scripts/models/enums/permissions.enum";

export default {
  components: {
    ChooseImportEmployee,
    UploadExcel,
    EmployeeDetails,
    ConciliationUploadDetails
  },
  props: {
    pageOptions: null
  },
  created() {
    this.campaignService
      .listAllCampaignMixAndPublicByCompanyId(this.$route.query.companyId)
      .then(
        function(data) {
          this.listCampaign = data;
          this.campaignId = this.listCampaign[0].id;
          let campaignIdInSession = parseInt(
            localStorage.getItem("campaignIdSelected")
          );
          var existCampaign = this.listCampaign.find(
            x => x.id == campaignIdInSession
          );
          if (!isNaN(campaignIdInSession) && existCampaign != null)
            this.campaignId = campaignIdInSession;
          this.loadDataCampaign(true);
        }.bind(this)
      );
    this.permissions = Permissions;
    this.$set(this.pageOptions, "CurrentPage", -1);
  },
  data() {
    return {
      firstCampaign: true,
      employeeService: new EmployeeService(),
      campaignService: new CampaignService(),
      docService: new DocService(),
      listImportEmployee: [],
      listStatus: [],
      campaign: null,
      campaignId: null,
      listCampaign: [],
      permissions: null,
      titleDetailsEmployee: "",
      currentImportEmployee: null,
      processingUpload: false,
      processingConciliationUpload: false,
      timer: null,
      typeUpload: null,
      textModalUpload: "",
      listPayRollConciliationActionDownload: [],
      listPayRollConciliationActionUpload: [],
      currentPayRollConciliationActionDownload: null,
      currentPayRollConciliationActionUpload: null
    };
  },
  computed: {
    showLoader() {
      if (this.processingUpload) return true;
      if (this.listImportEmployee == 0) return false;
      return false;
    },
    showConciliationLoader() {
      if (this.processingConciliationUpload) return true;
      if (this.listPayRollConciliationActionUpload == 0) return false;
      return false;
    },
    progressUpload() {
      return this.currentImportEmployee.step * (100 / 6);
    }
  },
  computed: {
    campaignSelected() {
      return this.listCampaign.filter(x => this.campaignId == x.id)[0];
    },
    ...mapGetters(["hasPermission"])
  },
  watch: {
    processingUpload() {
      const self = this;
      if (this.processingUpload && this.timer == null) {
        this.timer = setInterval(function() {
          self.loadDataCampaign(false);
        }, 5000);
      } else if (!this.processingConciliationUpload && this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    processingConciliationUpload() {
      const self = this;
      if (this.processingConciliationUpload && this.timer == null) {
        this.timer = setInterval(function() {
          self.loadDataCampaign(false);
        }, 5000);
      } else if (!this.processingUpload && this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    "currentImportEmployee.status"() {
      if (
        this.currentImportEmployee.status ===
          ImportEmployeeStatus.Processando ||
        this.currentImportEmployee.status === ImportEmployeeStatus.Pendente
      ) {
        this.processingUpload = true;
        return true;
      }
      this.processingUpload = false;
      return false;
    },
    "currentPayRollConciliationActionUpload.payRollConciliationActionStatus"() {
      if (
        this.currentPayRollConciliationActionUpload
          .payRollConciliationActionStatus ===
        PayRollConciliationActionStatus.Processing
      ) {
        this.processingConciliationUpload = true;
        return true;
      }
      this.processingConciliationUpload = false;
      return false;
    }
  },
  methods: {
    openModalUpload(typeUpload) {
      this.typeUpload = typeUpload;
      if (this.typeUpload == "employee") {
        this.textModalUpload =
          "Lembre-se que o arquivo de atualização da base <strong>deve conter sempre todos os funcionários.</strong> Não é cumulativo";
      } else if (this.typeUpload == "conciliation") {
        this.textModalUpload = "Upload da base a conciliar";
      }
      this.$refs.modalUpload.open();
    },
    changeSelect(event) {
      this.loadDataCampaign();
      localStorage.setItem("campaignIdSelected", event);
    },
    openModalEmployeeDetailsRemoved() {
      this.listStatus = [ImportStatus.RegisterRemoved];
      this.titleDetailsEmployee = "Saíram da Base";
      this.$refs.modalEmployeeDetails.open(
        this.currentImportEmployee.id,
        this.listStatus
      );
    },
    openModalEmployeeDetailsChangedEmailGroup() {
      this.listStatus = [ImportStatus.EmailOrGroupChanged];
      this.titleDetailsEmployee = "Mudaram de e-mail ou empresa do grupo";
      this.$refs.modalEmployeeDetails.open(
        this.currentImportEmployee.id,
        this.listStatus
      );
    },
    openModalEmployeeInconsistents() {
      this.listStatus = [
        ImportStatus.InvalidMail,
        ImportStatus.InvalidCnpj,
        ImportStatus.InvalidEmployeeIdentity,
        ImportStatus.CampaignNotAllowed
      ];
      this.titleDetailsEmployee = "Registros Inconsistentes";
      this.$refs.modalEmployeeDetails.open(
        this.currentImportEmployee.id,
        this.listStatus
      );
    },
    openModalEmployeeDuplicated() {
      this.listStatus = [
        ImportStatus.DuplicateEmail,
        ImportStatus.DuplicateEmployeeIdentity
      ];
      this.titleDetailsEmployee = "Registros Duplicados";
      this.$refs.modalEmployeeDetails.open(
        this.currentImportEmployee.id,
        this.listStatus
      );
    },
    openModalCampaignNotAllowed() {
      this.listStatus = [ImportStatus.CampaignNotAllowed];
      this.titleDetailsEmployee = "Empresa (CNPJ) fora da campanha";
      this.$refs.modalEmployeeDetails.open(
        this.currentImportEmployee.id,
        this.listStatus
      );
    },
    openModalNewEmployees() {
      this.listStatus = [ImportStatus.Created];
      this.titleDetailsEmployee = "Novos Funcionários";
      this.$refs.modalEmployeeDetails.open(
        this.currentImportEmployee.id,
        this.listStatus
      );
    },
    openModalConciliationDifferentValues() {
      this.listStatus = [
        PayRollConciliationActionDonationStatus.FailWithDifferentValues
      ];
      this.titleDetailsEmployee = "Valores Divergentes";
      this.$refs.modalConciliationUploadDetails.open(
        this.currentPayRollConciliationActionUpload.id,
        this.listStatus
      );
    },
    openModalConciliationNotUploaded() {
      this.listStatus = [
        PayRollConciliationActionDonationStatus.FailNotUploaded
      ];
      this.titleDetailsEmployee = "Valores não informados";
      this.$refs.modalConciliationUploadDetails.open(
        this.currentPayRollConciliationActionUpload.id,
        this.listStatus
      );
    },
    openModalConciliationNotOnConciliationByCompanyStatus() {
      this.listStatus = [
        PayRollConciliationActionDonationStatus.FailNotOnConciliationByCompanyStatus
      ];
      this.titleDetailsEmployee = "Valores não conciliados";
      this.$refs.modalConciliationUploadDetails.open(
        this.currentPayRollConciliationActionUpload.id,
        this.listStatus
      );
    },
    openModalConciliationDoesntExist() {
      this.listStatus = [
        PayRollConciliationActionDonationStatus.FailDoesntExist
      ];
      this.titleDetailsEmployee = "Não existentes";
      this.$refs.modalConciliationUploadDetails.open(
        this.currentPayRollConciliationActionUpload.id,
        this.listStatus
      );
    },
    loadDataCampaign(loading = false) {
      this.employeeService
        .listImportEmployeeByCampaignId(this.campaignId, loading)
        .then(
          function(data) {
            this.listImportEmployee = data;
            this.currentImportEmployee =
              this.listImportEmployee.length > 0
                ? this.listImportEmployee[0]
                : new ImportEmployee();
          }.bind(this)
        );

      this.employeeService
        .listPayRollConciliationAction(this.campaignId, loading)
        .then(
          function(data) {
            this.listPayRollConciliationActionDownload = [];
            this.listPayRollConciliationActionUpload = [];
            // if (data.length > 0) {
            this.listPayRollConciliationActionDownload = data.filter(
              payRollConciliationAction =>
                payRollConciliationAction.operation ==
                PayRollConciliationActionOperation.Download
            );
            this.listPayRollConciliationActionUpload = data.filter(
              payRollConciliationAction =>
                payRollConciliationAction.operation ==
                PayRollConciliationActionOperation.Upload
            );
            this.currentPayRollConciliationActionDownload =
              this.listPayRollConciliationActionDownload.length > 0
                ? this.listPayRollConciliationActionDownload[0]
                : null;

            this.currentPayRollConciliationActionUpload =
              this.listPayRollConciliationActionUpload.length > 0
                ? this.listPayRollConciliationActionUpload[0]
                : null;
            // }
          }.bind(this)
        );
    },
    uploadCallbackModal(formData) {
      if (this.typeUpload == "employee") {
        this.uploadEmployees(formData);
      } else if (this.typeUpload == "conciliation") {
        this.uploadConciliation(formData);
      }
    },
    uploadEmployees(formData) {
      this.employeeService.uploadBaseEmployees(formData).then(
        function(data) {
          this.finishOperation();
        }.bind(this)
      );
    },
    uploadConciliation(formData) {
      this.employeeService.uploadBaseToConciliate(formData).then(
        function(data) {
          this.finishOperation();
        }.bind(this)
      );
    },
    finishOperation() {
      this.$emit("reloadCampaign");
      this.loadDataCampaign();
    },
    downloadBaseEmployee() {
      this.employeeService.generateExcelBaseEmployee(this.campaignId).then(
        function(response) {
          if (response != null) {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/excel" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Base_Funcionários" +
                this.$moment().format("YYYY-MM-DD_H:m") +
                ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
          } else {
          }
        }.bind(this)
      );
    },
    schedulePDFs() {
      this.docService.schedulePDFsGeneration(this.campaignId);
    },
    downloadBaseToConciliate() {
      let fileName =
        "Base_a_Conciliar_" +
        this.campaignId +
        "_" +
        this.$moment().format("YYYY-MM-DD_H:m");
      this.employeeService
        .downloadBaseToConciliate(this.campaignId)
        .then(response => {
          this.downloadBlobExcel(fileName, response);
          this.finishOperation();
        });
    },
    downloadBaseToConciliateFromLog(payRollConciliation) {
      let data = this.$moment
        .utc(payRollConciliation.creationDate)
        .local("America/Sao_paulo")
        .format("YYYY-MM-DD_H:m");
      let fileName = "Base_a_Conciliar_" + this.campaignId + "_" + data;
      this.employeeService
        .downloadBaseToConciliateByPayRollConciliationActionId(
          payRollConciliation.id
        )
        .then(response => {
          this.downloadBlobExcel(fileName, response);
          this.finishOperation();
        });
    },
    downloadBlobExcel(nameFile, response) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameFile + ".xlsx");
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>