<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="600">
      <v-card>
        <v-card-text>
			<v-flex flex-row w-100>
				<v-flex pa-5 w-100>
					<h2>Como deseja atualizar a base de funcionários?</h2>
					<p class="mt-3">Quero enviar um arquivo excel:</p>
					<v-btn
						flat
						color="white"
						class="btn-primary ma-0 px-4"
						large
					><i class="fas fa-file-excel mr-3 f-size-16"></i> Enviar</v-btn>
					<hr class="md my-4">
					<p>Importar da última campanha:</p>
					<v-layout>
						<v-flex shrink mr-4>
							<v-btn
								flat
								color="white"
								class="btn-primary ma-0 px-4"
								large
							><i class="fas fa-file-import mr-3 f-size-16"></i> Importar</v-btn>
						</v-flex>
						<v-flex>
							<div class="f-size-11 fw-400 text-gray-dark no-wrap">26/09/2019 14:56</div>
							<h4>Nome campanha</h4>
							1254 funcionários
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex pb-5 text-xs-right>
					<button class="mr-5 fw-700 text-gray" @click="dialog = false; $emit('cancel')">CANCELAR</button>
				</v-flex>
			</v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
export default {
  props: ["value"],
  components: {},
  data() {
    return {
      dialog: false
    };
  },
  watch: {
    value() {
      this.dialog = this.value;
    }
  },
  methods: {
    open() {
      this.dialog = true;
    }
  }
};
</script>
