<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="700">
      <v-card>
        <v-card-title class="headline">
          <div class="header-base-dialog">
            <h1>{{ title }}</h1>
            <div>{{excelLines.length}}</div>
          </div>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog = false; $emit('cancel')">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap pa-4>
            <v-flex sm10>
              <InputSearch
                type="text"
                ref="searchInput"
                v-model="searchText"
                :focusOnCreate="true"
                place-holder="Buscar..."
              />
            </v-flex>
            <v-flex sm2>
              <div class="btn-download-base" @click="generateExcel">
                <label for="teste">
                  <Icon :name="'fas fa-download'" :size="16" colorFont="#fff" />
                </label>
              </div>
            </v-flex>
            <v-flex list-scroll mt-3 pr-3 sm12 style="max-height:calc(100vh - 400px)">
              <div class="content-register-base" v-for="item in filteredList" :key="item.id">
                <div>
                  <div>
                    <span class="mr-3">#{{item.id}}</span>
                    Linha: {{item.lineNumber}}
                  </div>
                  <div class="display-flex justify-content-center align-center">
                    <h3 class="mr-3">{{item.name}}</h3>
                    <span>CPF: {{item.cpf | FormatDocument}}</span>
                  </div>
                  <div>{{item.email}}</div>
                </div>
                <div>
                  <!-- <div>{{item.department}}</div> -->
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions class="pa-0">
          <div class="footer-modal-atividades">
            <v-btn
              flat
              round
              color="white"
              class="btn-primary px-5"
              large
              @click="dialog = false; $emit('cancel')"
            >Voltar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
import EmployeeService from "@/scripts/services/employee.service.js";
import ImportStatus from "@/scripts/models/enums/importStatus.enum";
export default {
  props: ["value", "title"],
  components: {},
  data() {
    return {
      dialog: false,
      searchText: "",
      employeeService: new EmployeeService(),
      excelLines: [],
      payRollConciliationActionId: 0,
      listStatus: []
    };
  },
  watch: {
    value() {
      this.dialog = this.value;
    }
  },
  computed: {
    filteredList() {
      return this.excelLines.filter(item => {
        return item.name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(this.searchText.toLowerCase());
      });
    }
  },
  methods: {
    open(payRollConciliationActionId, listStatus) {
      this.dialog = true;
      this.payRollConciliationActionId = payRollConciliationActionId;
      this.listStatus = listStatus;
      this.loadInfo(payRollConciliationActionId, listStatus);
    },
    loadInfo(payRollConciliationActionId, listStatus) {
      this.employeeService
        .listPayRollConciliationActionDonationByStatus(
          payRollConciliationActionId,
          listStatus
        )
        .then(
          function(data) {
            this.excelLines = [];
            data[0].payRollConciliationActionDonations.forEach(item => {
              item.payRollConciliationExcelLines.forEach(excelLine => {
                this.excelLines.push(excelLine);
              });
            });
          }.bind(this)
        );
    },
    generateExcel() {
      this.employeeService
        .generateExcelUploadConciliate(
          this.payRollConciliationActionId,
          this.listStatus
        )
        .then(
          function(response) {
            if (response != null) {
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: "application/excel" })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                "Retorno_Upload_" +
                  this.$moment().format("YYYY-MM-DD_H:m") +
                  ".xlsx"
              );
              document.body.appendChild(link);
              link.click();
            } else {
            }
          }.bind(this)
        );
    }
  }
};
</script>
