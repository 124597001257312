const PayRollConciliationActionDonationStatus =
{
     // Download ou upload com sucesso
     Success: 1,
     // Upload da doação com valor diferente
     FailWithDifferentValues: 2,
     // A doação (que estava no status em conciliação pela empresa) não constava no upload
     FailNotUploaded: 3,
     // A doação não estava em conciliação pela empresa
     FailNotOnConciliationByCompanyStatus: 4,
     // A doação não existe
     FailDoesntExist: 5
}
export default PayRollConciliationActionDonationStatus;