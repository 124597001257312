const ImportStatus = {
    InvalidEmployeeIdentity: 1,
    InvalidMail: 2,
    InvalidCnpj: 3,
    DuplicateEmployeeIdentity: 4,
    DuplicateEmail: 5,
    RegisterRemoved: 6,
    EmailOrGroupChanged: 7,
    Created: 8,
    NotModified: 9,
    CampaignNotAllowed: 10,
    Updated: 11
};

export default ImportStatus;