<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="600">
      <v-card>
        <v-card-text>
          <v-flex flex-row w-100>
            <v-flex pa-5 w-100>
              <p v-html="text">
              </p>
              <v-layout row>
                <v-flex xs8>
                  <InputText
                    ref="fileNameInput"
                    type="text"
                    place-holder="Selecione um arquivo"
                    invalidInputMessage="Escolha um arquivo"
                    v-model="fileName"
                  />
                </v-flex>
                <v-flex xs4 father-center>
                  <label for="upload-base" class="btn-search-rh ma-0 ml-3">Procurar</label>
                  <input
                    type="file"
                    id="upload-base"
                    ref="uploadFile"
                    class="d-none"
                    @change="fileChange"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex pb-5 text-xs-right>
              <button
                class="mr-5 fw-700 text-gray"
                @click="dialog = false; $emit('cancel')"
              >CANCELAR</button>
              <button
                :disabled="disabledUpload"
                class="send-archive-btn mr-5 fw-700 text-primary"
                @click="upload();"
              >ENVIAR ARQUIVO</button>
            </v-flex>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
export default {
  props: ["value", "campaignId", "text"],
  components: {},
  data() {
    return {
      dialog: false,
      fileName: null,
      disabledUpload: true,
    };
  },
  watch: {
    value() {
      this.dialog = this.value;
    }
  },
  methods: {
    open() {
      this.dialog = true;
      this.fileName = "";
    },
    fileChange(event) {
      this.fileName = event.target.files[0].name;
      if (this.fileName != "") this.disabledUpload = false;
    },
    upload() {
      let formData = new FormData();
      formData.append("file", this.$refs.uploadFile.files[0]);
      formData.append("campaignId", this.campaignId);
      this.$emit("upload", formData);
      this.dialog = false;
    }
  }
};
</script>
